module.exports = [{
      plugin: require('/Users/ansz/Desktop/Personal/andrewszucs.github.io/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/ansz/Desktop/Personal/andrewszucs.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-132438184-1"},
    },{
      plugin: require('/Users/ansz/Desktop/Personal/andrewszucs.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
